import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import IntercomAppWrapper from "./IntercomAppWrapper";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<IntercomAppWrapper />
		<ToastContainer
			theme="light"
			pauseOnHover
			autoClose={3500}
			hideProgressBar={true}
		/>
	</React.StrictMode>
);
