import React, { useState, useEffect } from "react";

import useFetch from "../../hooks/useFetch";

import Select from "./Select";

const SelectWebinar = () => {
	const [webinars, setWebinars] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const fetchWebinars = useFetch();

	useEffect(() => {
		fetchWebinars("/api/dentivatools/webinars/")
			.then((data) => {
				setIsLoading(false);
				setWebinars(data.webinars);
				console.log(data.webinars);
			})
			.catch((error) => console.error(error));
	}, []);

	if (isLoading) {
		return (
			<>
				<Select name="webinar" label="Webinar" required disabled>
					<option value="" disabled>
						Webinars aan het laden...
					</option>
				</Select>
			</>
		);
	} else {
		return (
			<Select name="webinar" label="Webinar" required>
				<option value="" disabled>
					Selecteer webinar...
				</option>
				{webinars &&
					webinars.map((webinar) => (
						<option value={webinar.id} key={webinar.id}>
							{webinar.title}
						</option>
					))}
				<option value="anders">Ander webinar...</option>
			</Select>
		);
	}
};

export default SelectWebinar;
