import React, { useState } from "react";

import { Formik, Form } from "formik";
import useFetch from "../../shared/hooks/useFetch";

import Input from "../../shared/components/Forms/Input";
import Button from "../../shared/components/Forms/Button";
import SelectWebinar from "../../shared/components/Forms/SelectWebinar";
import Container from "../../shared/components/UIElements/Container";
import YourWebinarLink from "../components/YourWebinarLink";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

const RequestLink = () => {
	const [link, setLink] = useState("");
	const [error, setError] = useState("");

	const initialValues = {
		email: "",
		webinar: "",
	};

	const fetchLink = useFetch();

	const findLink = (values) => {
		if (values.webinar === "anders") {
			setError(
				"Je kunt alleen een link opzoeken voor een webinar dat nog niet is geweest. Neem contact op via de chat als je de replay link kwijt bent."
			);
		} else {
			fetchLink(
				`/api/dentivatools/webinars/link/?email=${values.email}&webinarID=${values.webinar}`
			)
				.then((data) => {
					if (data.link) {
						setLink(data.link);
						setError("");
					} else if (data.error) {
						setError(data.error);
					}

					console.log(data);
				})
				.catch((error) => console.error(error));
		}
	};

	if (link === "") {
		return (
			<Container>
				<div className="sm:w-1/2">
					<h1 className="text-3xl font-bold mb-4 mt-8">
						Webinarlink kwijt?
					</h1>
					<p className="text-gray-500">
						Heb je je ingeschreven voor een live webinar, maar heb
						je geen link ontvangen? Vul dan hieronder je emailadres
						in dat je hebt gebruikt voor de inschrijving en
						selecteer het webinar waarvoor je je hebt ingeschreven.
						Je ziet dan direct je link.
					</p>
					<Formik initialValues={initialValues} onSubmit={findLink}>
						<Form>
							<SelectWebinar />
							<Input
								name="email"
								label="Email"
								type="email"
								required
								description="Het emailadres waarmee je je hebt ingeschreven voor het webinar"
								placeholder="Het emailadres waarmee je je hebt ingeschreven"
							/>

							<Button type="submit" classNames="w-full mt-4">
								<div className="flex justify-center">
									<MagnifyingGlassIcon className="w-5 mr-2" />
									Zoek webinarlink
								</div>
							</Button>
							{error && (
								<p className="text-red-500 mt-2">{error}</p>
							)}
						</Form>
					</Formik>
				</div>
			</Container>
		);
	} else {
		return <YourWebinarLink link={link} />;
	}
};

export default RequestLink;
