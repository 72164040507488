import React from "react";
import { Link } from "react-router-dom";

const Button = (props) => {
	const { classNames, ...rest } = props;

	let baseClasses;

	if (props.secondary) {
		baseClasses =
			classNames +
			" transition ease-in-out duration-300 rounded-lg bg-white border-3 box-border border-dentivaBlue mt-5 hover:scale-105 px-4 py-2.5 text-md font-semibold text-dentivaBlue shadow-sm hover:border-dentivaBlue-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dentivaBlue";
	} else {
		baseClasses =
			classNames +
			" transition ease-in-out duration-300 rounded-lg bg-dentivaBlue mt-5 hover:scale-105 px-6 py-3 text-md font-semibold text-white shadow-sm hover:bg-dentivaBlue-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dentivaBlue";
	}
	if (props.href) {
		return (
			<a className={baseClasses} href={props.href} {...rest}>
				{props.children}
			</a>
		);
	}
	if (props.to) {
		return (
			<Link to={props.to} className={baseClasses}>
				{props.children}
			</Link>
		);
	}
	return (
		<button
			className={baseClasses}
			documentType={props.documentType}
			onClick={props.onClick}
			disabled={props.disabled}
			{...rest}
		>
			{props.children}
		</button>
	);
};

export default Button;
