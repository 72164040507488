import React from "react";
import MainLogo from "../Branding/MainLogo";
import Container from "../UIElements/Container";
import HelpItem from "./HelpItem";

const NavBar = () => {
	return (
		<Container>
			<div className="flex align-middle items-center justify-between">
				<a href="https://dentiva.nl" target="_blank" rel="noreferrer">
					<MainLogo className="w-24 align-middle" />
				</a>
				<HelpItem />
			</div>
		</Container>
	);
};

export default NavBar;
