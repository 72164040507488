import React, { Suspense } from "react";

import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";

import NavBar from "./shared/components/Navigation/NavBar";

import RequestLink from "./webinars/pages/RequestLink";
import ManageTeam from "./webinars/pages/ManageTeam";

function App() {
	const routes = [
		<Route path="/webinarlink" element={<RequestLink />} />,
		<Route path="/praktijkpakket" element={<ManageTeam />} />,
		<Route path="*" element={<Navigate to="/webinarlink" />} />,
	];

	return (
		<Router>
			<NavBar />
			<main>
				<Suspense>
					<Routes>{routes}</Routes>
				</Suspense>
			</main>
		</Router>
	);
}

export default App;
