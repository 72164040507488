import React from "react";
import dentivaLogo from "../../../img/DentivaLogo.png";

const MainLogo = (props) => {
	return (
		<img src={dentivaLogo} alt="Dentiva Logo" className={props.className} />
	);
};

export default MainLogo;
