import React from "react";

import { DocumentDuplicateIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";

import Button from "./Button";

const CopyButton = (props) => {
	return (
		<Button
			onClick={() => {
				navigator.clipboard.writeText(props.copyText);
				toast.success("Gekopieerd naar je klembord", {
					position: "bottom-center",
					theme: "light",
					icon: (
						<CheckCircleIcon
							className=" text-green-500"
							aria-hidden="true"
						/>
					),
				});
			}}
		>
			<div className="flex">
				<DocumentDuplicateIcon className="w-5 mr-2" />
				Kopieer link
			</div>
		</Button>
	);
};

export default CopyButton;
