import React, { useState } from "react";
import Container from "../../shared/components/UIElements/Container";
import { Form, Formik } from "formik";
import SelectWebinar from "../../shared/components/Forms/SelectWebinar";
import Input from "../../shared/components/Forms/Input";
import Button from "../../shared/components/Forms/Button";
import {
	MagnifyingGlassCircleIcon,
	MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import useFetch from "../../shared/hooks/useFetch";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

const ManageTeam = () => {
	const [link, setLink] = useState("");
	const [error, setError] = useState("");
	const [message, setMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const initialValues = {
		email: "",
		webinar: "",
	};

	const fetchLink = useFetch();

	const findLink = (values) => {
		if (values.webinar === "anders") {
			setError(
				"Je kunt alleen een link opzoeken voor een webinar dat nog niet is geweest. Neem contact op via de chat om jouw link op te zoeken"
			);
			return;
		} else {
			setIsLoading(true);
			fetchLink(
				`/api/dentivatools/praktijkpakket-link/?email=${values.email}`
			)
				.then((data) => {
					console.log(data);
					if (data.link) {
						setMessage("Gevonden! We sturen je door...");
						window.location.href = data.link;
						setError("");
						setIsLoading(false);
					} else if (data.error) {
						setError(data.error);
						setIsLoading(false);
					}

					console.log(data);
				})
				.catch((error) => console.error(error));
		}
	};

	return (
		<Container>
			<div className="sm:w-1/2">
				<h1 className="text-3xl font-bold mb-4 mt-8">
					Voeg je team toe
				</h1>
				<p className="text-gray-500">
					Heb je je ingeschreven voor een webinar praktijkpakket, maar
					kun je de link niet meer vinden om je team toe te voegen?
					Vul dan hieronder je emailadres in dat je hebt gebruikt voor
					de inschrijving en selecteer het webinar waarvoor je je hebt
					ingeschreven.
				</p>
				<Formik initialValues={initialValues} onSubmit={findLink}>
					<Form>
						<SelectWebinar />
						<Input
							name="email"
							label="Email"
							type="email"
							required
							description="Het emailadres waarmee je het praktijkpakket hebt aangeschaft"
						/>

						<Button type="submit" classNames="w-full mt-4">
							<div className="flex justify-center">
								{isLoading && <LoadingSpinner />}
								{!isLoading && (
									<MagnifyingGlassIcon className="w-5 mr-2" />
								)}
								Voeg team toe
							</div>
						</Button>
						{error && <p className="text-red-500 mt-2">{error}</p>}
						{message && (
							<p className="text-green-600 font-medium mt-2">
								{message}
							</p>
						)}
					</Form>
				</Formik>
			</div>
		</Container>
	);
};

export default ManageTeam;
