import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import React from "react";

const HelpItem = () => {
	return (
		<a
			href="https://help.dentiva.nl/"
			target="_blank"
			className="align-middle flex bg-gray-200 py-2 px-5 rounded-lg cursor-pointer hover:scale-105 transition text-gray-700"
			rel="noreferrer"
		>
			<QuestionMarkCircleIcon className="w-5 mr-1 text-gray-600" />
			Hulp nodig?
		</a>
	);
};

export default HelpItem;
