import React from "react";

import Container from "../../shared/components/UIElements/Container";
import CopyButton from "../../shared/components/Forms/CopyButton";
import Button from "../../shared/components/Forms/Button";
import { CheckCircleIcon, PlayCircleIcon } from "@heroicons/react/20/solid";

const YourWebinarLink = ({ webinar, link, email }) => {
	return (
		<Container>
			<div className="sm:w-1/2">
				<h1 className="text-2xl font-bold flex text-green-500 mb-8">
					<CheckCircleIcon className="mr-1 w-6" />
					Je inschrijving is gevonden
				</h1>
				<p className="text-md mb-4">
					Jouw persoonlijke link voor het webinar is{" "}
					<a
						href={link}
						target="_blank"
						className="font-medium text-indigo-600 underline"
						rel="noreferrer"
					>
						{link}
					</a>
				</p>
				<CopyButton copyText={link} />

				<Button
					onClick={() => {
						window.open(link);
					}}
					classNames="ml-3"
					secondary
				>
					<div className="flex">
						<PlayCircleIcon className="w-5 mr-2" />
						Ga naar het webinar
					</div>
				</Button>
			</div>
		</Container>
	);
};

export default YourWebinarLink;
