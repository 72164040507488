import { useCallback } from "react";

export default function useFetch() {
	const baseURL = process.env.REACT_APP_BACKEND_URL;

	const authenticatedFetch = useCallback(
		async (endpoint, method = "GET", body = null, headers = {}) => {
			const options = {
				method,
				headers: {
					"Content-Type": "application/json",
					...headers,
				},
				body: body ? JSON.stringify(body) : null,
			};

			return fetch(`${baseURL}${endpoint}`, options).then((res) =>
				res.json()
			);
		},
		[]
	);

	return authenticatedFetch;
}
